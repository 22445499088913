import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Paper,
  Select,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import LoadingButton from "../../components/LoadingButton";
import { hostname, parseError } from "../../utils";

const ImportCSVModal = ({
  open,
  onClose,
  getToken,
  eventListener,
  companyId,
  listId,
}: {
  open: boolean;
  onClose: () => void;
  getToken: Function;
  eventListener: Function;
  companyId: string;
  listId: string;
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [existingHeaders, setExistingHeaders] = useState<Array<any>>([]);
  const [headerMapping, setHeaderMapping] = useState<any>({});
  const [preview, setPreview] = useState([]);

  const handleFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleImport = async () => {
    if (!selectedFile) return;
    setLoading(true);
    const currentToken = await getToken();
    var formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("companyId", companyId);
    formData.append("headerMapping", JSON.stringify(headerMapping));
    axios
      .post(`${hostname}/lists/${listId}/import`, formData, {
        headers: { Authorization: `Bearer ${currentToken}` },
      })
      .then(() => {
        onClose();
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePreview = async () => {
    if (!selectedFile) return;
    setLoading(true);
    const currentToken = await getToken();
    var formData = new FormData();
    formData.append("file", selectedFile);
    axios
      .post(`${hostname}/lists/${listId}/import/preview`, formData, {
        responseType: "json",
        headers: { Authorization: `Bearer ${currentToken}` },
      })
      .then((res) => {
        if (res.data.headers) {
          setHeaders(res.data.headers);
          setPreview(res.data.preview);
          setExistingHeaders(res.data.existingHeaders);
          setHeaderMapping(res.data.headerMapping);
          setActiveStep(activeStep + 1);
        }
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getHeaderValue = (header: string) => {
    return headerMapping[header] || "ignore";
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Import CSV</DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep}>
          <Step>
            <StepLabel>Upload File</StepLabel>
          </Step>
          <Step>
            <StepLabel>Select Columns</StepLabel>
          </Step>
        </Stepper>
        <Box sx={{ display: activeStep === 0 ? "block" : "none" }}>
          <Typography sx={{ mt: 2 }} variant="body2" gutterBottom>
            Upload a CSV file to import data.
          </Typography>
          <Box>
            <Button
              variant="outlined"
              component="label"
              startIcon={<UploadFileIcon />}
            >
              {selectedFile ? selectedFile.name : "Choose File"}
              <input
                type="file"
                accept=".csv"
                hidden
                onChange={handleFileChange}
              />
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: activeStep === 1 ? "block" : "none" }}>
          <Typography sx={{ mt: 2, mb: 2 }}>
            Match CSV column headers with existing or choose to create a new
            column header
          </Typography>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headers.map((header, i) => (
                      <TableCell key={i}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {preview.map((row, i) => (
                    <TableRow key={i}>
                      {headers.map((header, ii) => (
                        <TableCell key={ii}>{row[header]}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                  <TableRow>
                    {headers.map((header, i) => (
                      <TableCell key={i}>
                        <Select
                          value={getHeaderValue(header)}
                          onChange={(e) =>
                            setHeaderMapping({
                              ...headerMapping,
                              [header]: e.target.value,
                            })
                          }
                        >
                          <MenuItem value="new">+ New Column</MenuItem>
                          <MenuItem value="ignore">Ignore</MenuItem>
                          {existingHeaders.map((head, ii) => (
                            <MenuItem key={ii} value={head.id}>
                              {head.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <LoadingButton
          onClick={activeStep === 0 ? handlePreview : handleImport}
          variant="outlined"
          loading={loading}
        >
          Import
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ImportCSVModal;
