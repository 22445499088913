import { Warning } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import LoadingButton from "../../components/LoadingButton";
import ModelSelector from "../../components/ModelSelector";
import { defaultModel } from "../../constants";
import { hostname, parseError } from "../../utils";

const ResearchAssistantDialog = ({
  open,
  onClose,
  headers,
  listId,
  companyId,
  getToken,
  eventListener,
  currentPlan,
  actionParameters,
}: {
  open: boolean;
  onClose: () => void;
  headers: Array<any>;
  listId: string;
  companyId: string;
  getToken: Function;
  eventListener: Function;
  currentPlan: string;
  actionParameters: any;
}) => {
  const [model, setModel] = useState(defaultModel);
  const [showDropdown, setShowDropdown] = useState(false);
  const [goal, setGoal] = useState("");
  const inputRef = useRef<any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState(false);
  const [actionId, setActionId] = useState("");
  const [action, setAction] = useState("update_rows");
  const [autoRunEnabled, setAutoRunEnabled] = useState(false);
  const [hideAction, setHideAction] = useState(false);

  const handleInputChange = (event: any) => {
    const newValue = event.target.value;
    setGoal(newValue);
    // Check for "/" and the cursor position

    if (
      inputRef.current &&
      newValue[inputRef.current.selectionStart - 1] === "/"
    ) {
      setAnchorEl(inputRef.current);
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  const handleClose = () => {
    setActionId("");
    setModel("");
    setGoal("");
    setAction("update_rows");
    setAutoRunEnabled(false);
    setHideAction(false);
    onClose();
  };

  const handleSubmit = async () => {
    console.log("submit", goal, model);
    if (!goal || !model) return;
    setLoading(true);
    const currentToken = await getToken();
    axios
      .post(
        `${hostname}/lists/${listId}/action`,
        {
          companyId,
          model,
          goal,
          actionId,
          action,
          autoRunEnabled,
        },
        {
          headers: { Authorization: `Bearer ${currentToken}` },
        }
      )
      .then(() => {
        setActionId("");
        setModel("");
        setGoal("");
        setAction("update_rows");
        setAutoRunEnabled(false);
        setHideAction(false);
        onClose();
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    console.log("actionParameters", actionParameters);
    if (actionParameters) {
      setHideAction(true);
      setActionId(actionParameters.actionId);
      setAction(actionParameters.action);
      setModel(actionParameters.model);
      setGoal(actionParameters.goal);
      setAutoRunEnabled(actionParameters.autoRunEnabled);
    }
  }, [actionParameters]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>🤖 Al Web Scraper: Research Agent</DialogTitle>
      <DialogContent style={{ paddingTop: "16px" }}>
        <div>
          <Typography variant="subtitle1">Enable Auto-Run</Typography>
          <Typography variant="body2" style={{ marginTop: "8px" }}>
            Auto-Run executes this action on new rows immedialtly.
          </Typography>
          <Switch
            checked={autoRunEnabled}
            onChange={(e) => setAutoRunEnabled(!autoRunEnabled)}
            color="primary"
          />
        </div>

        {/*
        <div style={{ marginTop: "24px" }}>
          <Typography variant="subtitle1">
            Run Conditions{" "}
            <span
              style={{
                backgroundColor: "lightgray",
                padding: "2px 5px",
                borderRadius: "4px",
              }}
            >
              0
            </span>
          </Typography>
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "8px" }}
          >
            <Typography variant="body2" style={{ marginRight: "8px" }}>
              AND
            </Typography>
            <Button variant="outlined" style={{ marginRight: "8px" }}>
              <AddIcon fontSize="small" style={{ marginRight: "4px" }} />
              Add filter
            </Button>
            <Button variant="outlined">
              <AddIcon fontSize="small" style={{ marginRight: "4px" }} />
              Add Group
            </Button>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "24px",
          }}
        >
          <Button variant="outlined" startIcon={<FolderOpenIcon />}>
            Browse Templates (4)
          </Button>
          <Button variant="outlined" startIcon={<SaveIcon />}>
            Save as Template
          </Button>
        </div> */}

        <div style={{ marginTop: "16px", display: hideAction ? "none" : "" }}>
          <Typography variant="subtitle1">Agent Action</Typography>
          <Typography variant="body2">
            Choose your agent action. Do you want to fill in information for
            existing rows or add new rows?
          </Typography>
          <FormControl fullWidth style={{ marginTop: 20 }}>
            <InputLabel>Action</InputLabel>
            <Select
              label="Action"
              value={action}
              onChange={(e) => setAction(e.target.value)}
            >
              <MenuItem value="update_rows">Update Rows</MenuItem>
              <MenuItem value="add_rows">Add Rows</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div style={{ marginTop: "16px" }}>
          <Typography variant="subtitle1">Model</Typography>
          <Typography variant="body2">
            Choose your model. Some models are more expensive but provide higher
            quality results.
          </Typography>
          <ModelSelector
            currentPlan={currentPlan}
            onChange={(e) => setModel(e.target.value)}
            value={model}
            tools
          />
        </div>

        <div style={{ marginTop: "24px" }}>
          <Typography variant="subtitle1">Your Goal</Typography>
          <Typography variant="body2">
            Divide the task into smaller steps. Give clear instructions for each
            step, specifying the expected output.
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={12}
            placeholder="Navigate to {{Company Website}} 'Careers' page. Check if they're hiring SDRs or Account\nExecutives. If so, summarize the top three responsibilities in 20 words or less."
            variant="outlined"
            value={goal}
            onChange={handleInputChange}
            inputRef={inputRef}
            style={{ marginTop: "8px" }}
          />
          <Menu
            anchorEl={anchorEl}
            open={showDropdown}
            onClose={() => setShowDropdown(false)}
            sx={{ maxHeight: 300 }}
          >
            <MenuItem
              onClick={() => {
                setGoal(
                  goal.substring(0, goal.length - 1) + `{{Company Description}}`
                );
                setShowDropdown(false);
              }}
            >
              Company Description
            </MenuItem>
            {action === "update_rows" && (
              <MenuItem
                onClick={() => {
                  setGoal(
                    goal.substring(0, goal.length - 1) + `{{Full Record}}`
                  );
                  setShowDropdown(false);
                }}
              >
                Full Record
              </MenuItem>
            )}
            {action === "update_rows" &&
              headers.map((header, i) => (
                <MenuItem
                  key={i}
                  onClick={() => {
                    setGoal(
                      goal.substring(0, goal.length - 1) + `{{${header.field}}}`
                    );
                    setShowDropdown(false);
                  }}
                >
                  {header.field}
                </MenuItem>
              ))}
          </Menu>
          <Typography variant="caption" style={{ marginTop: "4px" }}>
            Type{" "}
            <span
              style={{
                backgroundColor: "lightgray",
                padding: "2px 4px",
                borderRadius: "4px",
                color: "black",
              }}
            >
              /
            </span>{" "}
            to Insert Row information or your Company Description
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={{ marginRight: "8px" }}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled
          sx={{ display: goal && model ? "none" : "block" }}
        >
          <Warning fontSize="small" style={{ marginRight: "4px" }} />
          Missing Fields
        </Button>
        <LoadingButton
          sx={{ display: goal && model ? "flex" : "none" }}
          loading={loading}
          onClick={handleSubmit}
          variant="outlined"
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ResearchAssistantDialog;
