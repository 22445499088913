import { keyframes, styled } from "@mui/system";
import React from "react";

const blinkAnimation = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const BlinkingCursor = styled("span")(({ theme }) => ({
  width: "12px",
  height: "20px",
  backgroundColor: theme.palette.text.primary,
  marginLeft: "2px",
  animation: `${blinkAnimation} 1s linear infinite`,
  display: "inline-block",
}));

const LoadingCursor = ({
  children,
  loading,
}: {
  children: any;
  loading: boolean;
}) => {
  return (
    <span>
      {children}
      {loading && <BlinkingCursor />}
    </span>
  );
};

export default LoadingCursor;
