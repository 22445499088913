import {
  Business,
  Group,
  ListAlt,
  MoreVert,
  Refresh,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import { User } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Company } from "../../types";
import { hostname, parseError } from "../../utils";
import CreateListDialog from "./CreateListDialog";
import ListDetail from "./ListDetail";
import ListSharing from "./ListSharing";

export default ({
  mode,
  currentUser,
  getToken,
  eventListener,
  companyId,
  company,
  currentPlan,
}: {
  mode: string;
  getToken: Function;
  eventListener: Function;
  currentUser: any | User;
  companyId: string;
  company: Company;
  currentPlan: string;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [createOpen, setCreateOpen] = useState(false);
  const [listSelected, setListSelected] = useState<any>(null);
  const [refreshing, setRefreshing] = useState(false);
  const [lists, setLists] = useState<Array<any>>([]);
  const [sharingOpen, setSharingOpen] = useState(false);
  const [listSelectedSharing, setListSelectedSharing] = useState<any>(null);

  const getData = async () => {
    console.log("Get data");
    setRefreshing(true);
    const currentToken = await getToken();
    axios
      .get(`${hostname}/lists`, {
        params: { companyId: companyId },
        headers: { Authorization: `Bearer ${currentToken}` },
      })
      .then((res) => {
        setLists(res.data.lists);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setRefreshing(false);
      });
  };

  const openList = (list: any) => {
    console.log("Open List");
    setListSelected(list);
  };

  const deleteList = async (listId: string) => {
    const currentToken = await getToken();
    axios
      .delete(`${hostname}/lists/${listId}`, {
        params: { companyId },
        headers: { Authorization: `Bearer ${currentToken}` },
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        getData();
      });
  };

  const openSharing = (list: any) => {
    setListSelectedSharing(list);
    setSharingOpen(true);
  };

  useEffect(() => {
    if (currentUser.uid) {
      getData();
    }
  }, [currentUser, companyId]);

  return (
    <Box sx={{ pt: 2 }}>
      <Box sx={{ display: !listSelected ? "none" : "block" }}>
        <ListDetail
          mode={mode}
          currentUser={currentUser}
          company={company}
          companyId={companyId}
          getToken={getToken}
          eventListener={eventListener}
          currentPlan={currentPlan}
          listSelected={listSelected}
          setListSelected={setListSelected}
        />
      </Box>
      <Box sx={{ display: listSelected ? "none" : "block" }}>
        <Button
          variant="outlined"
          onClick={() => setCreateOpen(true)}
          startIcon={<ListAlt />}
        >
          Create List
        </Button>
        <Paper style={{ marginTop: 20, marginBottom: 40 }} variant="outlined">
          {refreshing && <LinearProgress />}
          {!refreshing && <div style={{ height: 4 }}></div>}
          <Box style={{ display: "flex" }}>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton
              onClick={() => getData()}
              style={{ float: "right", marginRight: 3 }}
            >
              <Refresh />
            </IconButton>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Type</TableCell>
                  <TableCell align="right">Sharing</TableCell>
                  <TableCell align="right">Count</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lists.map((list) => (
                  <TableRow
                    key={list.id}
                    sx={{ cursor: "pointer" }}
                    onClick={() => openList(list)}
                  >
                    <TableCell>🚀 {list.name}</TableCell>
                    <TableCell align="right">
                      {list.type === "companies" ? <Business /> : <Group />}
                    </TableCell>
                    <TableCell align="right">
                      <Chip
                        variant="outlined"
                        color={
                          list.sharing === "Shared" ? "primary" : "default"
                        }
                        label={list.sharing}
                      />
                    </TableCell>
                    <TableCell align="right">{list.count}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setAnchorEl(e.currentTarget);
                        }}
                      >
                        <MoreVert />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={anchorEl !== null}
                        onClose={(e: any) => {
                          e.stopPropagation();
                          setAnchorEl(null);
                        }}
                      >
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            openList(list);
                            setAnchorEl(null);
                          }}
                        >
                          View
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            openSharing(list);
                            setAnchorEl(null);
                          }}
                        >
                          Edit Sharing
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteList(list.id);
                            setAnchorEl(null);
                          }}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      <CreateListDialog
        open={createOpen}
        onClose={() => {
          setCreateOpen(false);
          getData();
        }}
        getToken={getToken}
        companyId={companyId}
        eventListener={eventListener}
      />
      <ListSharing
        open={sharingOpen}
        onClose={() => {
          setSharingOpen(false);
          setListSelectedSharing(null);
          getData();
        }}
        list={listSelectedSharing}
        getToken={getToken}
        eventListener={eventListener}
      />
    </Box>
  );
};
