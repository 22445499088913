import { AutoAwesome } from "@mui/icons-material";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import LoadingButton from "../../components/LoadingButton";
import { LIST_TYPE_MAPPING } from "../../constants";
import { hostname, parseError } from "../../utils";

const CreateListDialog = ({
  open,
  onClose,
  getToken,
  companyId,
  eventListener,
}: {
  open: boolean;
  onClose: Function;
  getToken: Function;
  companyId: string;
  eventListener: Function;
}) => {
  const [listName, setListName] = useState("");
  const [loading, setLoading] = useState(false);
  const [listType, setListType] = useState("");

  const onCreate = async () => {
    if (!listType || !listName) {
      return;
    }
    const currentToken = await getToken();
    setLoading(true);
    axios
      .post(
        `${hostname}/lists`,
        {
          companyId: companyId,
          listDict: {
            name: listName,
            type: listType,
            fields: LIST_TYPE_MAPPING[listType],
          },
        },
        {
          headers: { Authorization: `Bearer ${currentToken}` },
        }
      )
      .then(() => {
        setListName("");
        setListType("");
        onClose();
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>Create Smart list</DialogTitle>
      <DialogContent>
        {/* <TextField
          fullWidth
          margin="normal"
          placeholder="Start from template"
          InputProps={{
            startAdornment: <BusinessIcon />,
          }}
        />
        <Typography variant="body2" align="center" sx={{ margin: "16px 0" }}>
          or
        </Typography>
        <Typography variant="subtitle1">Start from scratch</Typography> */}
        <Typography variant="body2" gutterBottom>
          Choose the type of list you want to create
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              disabled={listType === "leads"}
              onClick={() => setListType("leads")}
              variant="outlined"
              fullWidth
              startIcon={<PeopleAltIcon />}
            >
              Leads
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={listType === "companies"}
              onClick={() => setListType("companies")}
              variant="outlined"
              fullWidth
              startIcon={<BusinessIcon />}
            >
              Companies
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={listType === "custom"}
              onClick={() => setListType("custom")}
              variant="outlined"
              fullWidth
              startIcon={<AutoAwesome />}
            >
              Custom Smart List
            </Button>
          </Grid>
        </Grid>
        <TextField
          fullWidth
          margin="normal"
          label="List name"
          placeholder="e.g. 'SaaS Li Profile Visits'"
          value={listName}
          onChange={(e) => setListName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Close</Button>
        <LoadingButton
          loading={loading}
          variant="outlined"
          onClick={() => onCreate()}
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateListDialog;
