import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Memory, Message } from "../../types";
import { truncate } from "../../utils";

export interface SimpleDialogProps {
  open: boolean;
  message: Message | null | undefined;
  onClose: () => void;
}

export default (props: SimpleDialogProps) => {
  const { onClose, message, open } = props;
  const [expanded, setExpanded] = useState("");

  const getContextText = (match: Memory): string => {
    const score = (match.score || 0).toFixed(2);
    return `Score: ${score}, ${truncate(match.content)}`;
  };

  if (!message) {
    return <div></div>;
  }

  return (
    <Dialog onClose={() => onClose()} open={open} scroll="paper" maxWidth="md">
      <DialogTitle>Message Context</DialogTitle>
      <DialogContent>
        {message.context.memories.map((m) => (
          <Accordion
            key={m.id}
            expanded={expanded === m.id}
            onChange={() => setExpanded(m.id)}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography style={{ marginRight: 5 }}>{m.type}</Typography>
              <Typography sx={{ color: "text.secondary" }}>
                {getContextText(m)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{m.content}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
