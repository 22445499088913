import { Chip, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { modelsLabelsList } from "../constants";

export default ({
  currentPlan,
  value,
  onChange,
  tools=false,
}: {
  currentPlan: string;
  value: string;
  onChange: (event: any) => void;
  tools: boolean;
}) => {
  const filteredList = modelsLabelsList.filter(
    (x) => currentPlan !== "free" && x.free
  );

  return (
    <FormControl fullWidth style={{ marginTop: 20 }}>
      <InputLabel>Model</InputLabel>
      <Select
        label="Model"
        value={value || filteredList[0].name}
        onChange={onChange}
      >
        {modelsLabelsList.map((model) => (
          <MenuItem
            key={model.name}
            value={model.name}
            disabled={
              (currentPlan === "free" && !model.free) ||
              (tools && !model.toolsModel)
            }
          >
            <Chip label={model.provider} style={{ marginRight: 5 }} />
            <Chip
              label={model.credits + " credits"}
              style={{ marginRight: 5 }}
            />{" "}
            {model.tags.map((tag) => (
              <Chip key={tag} label={tag} style={{ marginRight: 5 }} />
            ))}
            {model.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
