import {
  Box,
  Dialog,
  DialogContent,
  LinearProgress,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import KnowledgeBaseSelect from "../../components/KnowledgeBaseSelect";
import LoadingButton from "../../components/LoadingButton";
import { hostname } from "../../utils";

export default ({
  open,
  onClose,
  getToken,
  companyId,
  eventListener,
  company,
}: {
  open: boolean;
  onClose: () => void;
  getToken: Function;
  companyId: string;
  eventListener: Function;
  company: any;
}) => {
  const [importProgress, setImportProgress] = useState(0);
  const [maxDepth, setMaxDepth] = useState(3);
  const [maxCrawl, setMaxCrawl] = useState(25);
  const [importProgressText, setImportProgressText] = useState("");
  const [destinationFolder, setDestinationFolder] = useState("/");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const crawlWebsite = async () => {
    setImportProgress(1);
    setImportProgressText("Starting crawler...");
    setLoading(true);
    const currentToken = await getToken();
    if (websiteUrl.length < 6) return;
    const response = await fetch(`${hostname}/websites/import`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
      body: JSON.stringify({
        url: websiteUrl,
        companyId: companyId,
        maxCrawl: maxCrawl,
        maxDepth: maxDepth,
        path: destinationFolder,
      }),
    });
    if (!response.body) return;
    const reader = response.body
      .pipeThrough(new TextDecoderStream())
      .getReader();
    while (true) {
      var { value, done } = await reader.read();
      if (done) {
        console.log("Done triggered", done, value);
        break;
      }
      if (value) {
        try {
          const parsed = JSON.parse(value);
          setImportProgress((parsed.completed / parsed.total) * 100);
          setImportProgressText(
            `Processed ${parsed.completed} of ${parsed.total}`
          );
        } catch (e) {
          console.log(e);
        }
      }
    }
    setWebsiteUrl("");
    setLoading(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      fullWidth
      maxWidth="md"
    >
      <DialogContent>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Import Website Crawler
        </Typography>
        <TextField
          style={{ marginTop: 20 }}
          fullWidth
          value={websiteUrl}
          label="Website URL"
          placeholder="https://example.com"
          onChange={(e) => setWebsiteUrl(e.target.value)}
        />
        <Typography sx={{ mt: 2 }}>Max Pages: {maxCrawl}</Typography>
        <Slider
          sx={{ width: "60%" }}
          value={maxCrawl}
          onChange={(e: any) => setMaxCrawl(e.target.value)}
          min={1}
          max={100}
          valueLabelDisplay="auto"
          getAriaValueText={(v) => `${v}`}
        />
        <Typography sx={{ mt: 2 }}>Max Depth: {maxDepth}</Typography>
        <Slider
          sx={{ width: "60%" }}
          value={maxDepth}
          onChange={(e: any) => setMaxDepth(e.target.value)}
          min={1}
          max={6}
          valueLabelDisplay="auto"
          getAriaValueText={(v) => `${v}`}
        />
        <KnowledgeBaseSelect
          company={company}
          getToken={getToken}
          eventListener={eventListener}
          value={destinationFolder}
          onValueChange={setDestinationFolder}
        />
        <Box sx={{ width: "100%" }}>
          <LoadingButton
            loading={loading}
            variant="outlined"
            sx={{ mt: 2 }}
            onClick={() => crawlWebsite()}
          >
            Import
          </LoadingButton>
        </Box>
        <Typography sx={{ mt: 2, display: loading ? "block" : "none" }}>
          {importProgressText}
        </Typography>
        <LinearProgress
          sx={{ mb: 1, display: loading ? "block" : "none" }}
          variant="determinate"
          value={importProgress}
        />
      </DialogContent>
    </Dialog>
  );
};
