import { Box, Container, Snackbar, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import ChatConversation from "../components/ChatConversation";
import Footer from "../home/Footer";
import Header from "../home/Header";
import { EventObj } from "../types";
import { hostname, parseError } from "../utils";

const eventsReducer = (state: EventObj, action: any) => {
  switch (action.type) {
    case "SET_ERROR":
      return { ...state, error: action.error };
    case "SET_INFO":
      return { ...state, error: action.info };
    default:
      throw new Error();
  }
};

export default ({ mode }: { mode: string }) => {
  const { conversationId } = useParams();
  const [chat, setChat] = useState([]);
  const [event, eventListener] = useReducer(eventsReducer, { error: "" });

  const loadChat = async () => {
    axios
      .get(`${hostname}/shared/${conversationId}`)
      .then((res) => {
        setChat(res.data.chat);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const handleCloseError = () => {
    eventListener({ type: "SET_ERROR", error: "" });
  };

  useEffect(() => {
    loadChat();
  }, []);

  return (
    <Box>
      <Header mode={mode} maxWidth={"xl"} />

      <Box sx={{ width: "100%", textAlign: "center", mt: 2 }}>
        <Typography color={"gray"}>
          This is a copy of a conversation with ParallelAI.
        </Typography>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={event.error !== ""}
        autoHideDuration={6000}
        message={event.error}
        onClose={handleCloseError}
      />
      <Container>
        <ChatConversation
          chat={chat}
          mode={mode}
          eventListener={eventListener}
          setDialogSelectedMessage={() => {}}
        />
      </Container>
      <Footer />
    </Box>
  );
};
