import { Description, Source } from "@mui/icons-material";
import {
  Autocomplete,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import IconMenuItem from "../../components/IconMenuItem";
import LoadingButton from "../../components/LoadingButton";
import { modelsLabelsList } from "../../constants";
import { Company } from "../../types";
import { hostname, parseError } from "../../utils";
import OutlineEditor from "../blog/OutlineEditor";

export default ({
  open,
  onClose,
  title,
  articleOnly,
  getToken,
  eventListener,
  company,
  onCreateProject,
}: {
  open: boolean;
  onClose: () => void;
  title: string;
  articleOnly: boolean;
  getToken: Function;
  eventListener: Function;
  company: Company;
  onCreateProject: (project: any) => void;
}) => {
  const [init, setInit] = useState(false);
  const [projectDialogLoading, setProjectDialogLoading] = useState(false);
  const [projectObjective, setProjectObjective] = useState("");
  const [projectType, setProjectType] = useState(
    articleOnly ? "article" : "research_report"
  );
  const [webResearch, setWebResearch] = useState(true);
  const [knowledgeBase, setKnowledgeBase] = useState(false);
  const [folderPath, setFolderPath] = useState("/");
  const [outline, setOutline] = useState<any>({});
  const [outlineEditorOpen, setOutlineEditorOpen] = useState(false);
  const [keywords, setKeywords] = useState<Array<string>>([]);
  const [model, setModel] = useState(
    modelsLabelsList.filter((x) => x.writingModel)[0].name
  );

  const [documents, setDocuments] = useState<Array<any>>([]);
  const [documentScope, setDocumentScope] = useState("none");
  const [targetAudience, setTargetAudience] = useState("General");
  const projectTypes = articleOnly
    ? [
        { name: "Article (800-1500 words)", value: "article" },
        { name: "Large Article (2000-4000 words)", value: "large_article" },
      ]
    : [
        { name: "Research Report", value: "research_report" },
        { name: "Resource Report", value: "resource_report" },
        { name: "Outline Report", value: "outline_report" },
        { name: "Article (800-1500 words)", value: "article" },
        { name: "Large Article (2000-4000 words)", value: "large_article" },
      ];

  const submitOutline = async (e: any) => {
    e.preventDefault();
    await createProject();
  };

  const getDocuments = async (
    path: string,
    selectedDoc: Document | undefined = undefined
  ) => {
    const currentToken = await getToken();
    await axios
      .get(`${hostname}/documents`, {
        headers: { Authorization: `Bearer ${currentToken}` },
        params: { path: path, companyId: company.id },
      })
      .then((res) => {
        var documentList = res.data;
        if (selectedDoc) {
          setDocuments([selectedDoc, ...documentList]);
        } else {
          setDocuments(documentList);
        }
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const createProject = async () => {
    if (!projectObjective || !projectType) return;
    const currentToken = await getToken();
    setProjectDialogLoading(true);
    await axios
      .post(
        `${hostname}/projects`,
        {
          name: projectObjective,
          objective: projectObjective,
          type: projectType,
          companyId: company.id,
          documentScope: documentScope,
          targetAudience: targetAudience,
          outline: outline,
          model: model,
          webResearch: webResearch,
          knowledgeBase: knowledgeBase,
          keywords: keywords,
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .then((res) => {
        onClose();
        onCreateProject(res.data.project);
        setProjectObjective("");
        setOutline({});
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setProjectDialogLoading(false);
        setOutlineEditorOpen(false);
      });
  };

  const handleDocumentChange = (e: any) => {
    const selectedDoc = documents.find((x) => x.id === e.target.value);
    setDocumentScope(e.target.value);
    if (e.target.value === "root") {
      setFolderPath("/");
      getDocuments("/");
    }
    if (e.target.value === "/shared") {
      setFolderPath("/shared");
      getDocuments("/shared");
    }
    if (!selectedDoc) {
      return;
    }
    if (selectedDoc.type === "folder") {
      const newPath =
        selectedDoc.path !== "/"
          ? `${selectedDoc.path}/${selectedDoc.name}`
          : `/${selectedDoc.name}`;
      setFolderPath(newPath);
      getDocuments(newPath, selectedDoc);
    }
  };

  const createProjectParent = async () => {
    if (projectType === "large_article") {
      await generateOutline();
    } else {
      createProject();
    }
  };

  const generateOutline = async () => {
    const currentToken = await getToken();
    setProjectDialogLoading(true);
    axios
      .post(
        `${hostname}/projects/outline`,
        {
          name: projectObjective,
          objective: projectObjective,
          type: projectType,
          companyId: company.id,
          documentScope: documentScope,
          targetAudience: targetAudience,
          model: model,
          webResearch: webResearch,
          knowledgeBase: knowledgeBase,
          keywords: keywords,
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .then((res) => {
        setOutline(res.data.outline);
        setOutlineEditorOpen(true);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setProjectDialogLoading(false);
      });
  };

  if (open && !init) {
    getDocuments(folderPath);
    setInit(true);
  }

  return (
    <React.Fragment>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose options for a Parallel AI project.
          </DialogContentText>
          <DialogContentText style={{ marginTop: 10, marginBottom: 10 }}>
            Use the document scope to pull internal documents into the project
            for specific subjects.
          </DialogContentText>
          <FormControlLabel
            control={
              <Switch
                onChange={(e) => {
                  setWebResearch(e.target.checked);
                }}
                checked={webResearch}
              />
            }
            label="Web Research"
          />
          <FormControlLabel
            control={
              <Switch
                onChange={(e) => {
                  setKnowledgeBase(e.target.checked);
                }}
                checked={knowledgeBase}
              />
            }
            label="Knowledge Base"
          />
          <TextField
            style={{ marginTop: 20 }}
            label="Project Objective"
            value={projectObjective}
            fullWidth
            multiline
            rows={3}
            onChange={(e: any) => setProjectObjective(e.target.value)}
          />
          <FormControl fullWidth style={{ marginTop: 20 }}>
            <InputLabel>
              {articleOnly ? "Article Type" : "Project Type"}
            </InputLabel>
            <Select
              label={articleOnly ? "Article Type" : "Project Type"}
              value={projectType}
              onChange={(e) => setProjectType(e.target.value)}
            >
              {projectTypes.map((projectType) => (
                <MenuItem key={projectType.value} value={projectType.value}>
                  {projectType.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ marginTop: 20 }}>
            <InputLabel>Model</InputLabel>
            <Select
              label="Model"
              value={model}
              onChange={(e) => setModel(e.target.value)}
            >
              {modelsLabelsList
                .filter((x: any) => x.writingModel)
                .filter(
                  (x: any) =>
                    (projectType === "article" && x.provider === "OpenAI") ||
                    projectType !== "article"
                )
                .map((model: any) => (
                  <MenuItem key={model.name} value={model.name}>
                    <Chip label={model.provider} style={{ marginRight: 5 }} />
                    {model.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <TextField
            style={{ marginTop: 20 }}
            label="Target Audience"
            value={targetAudience}
            fullWidth
            onChange={(e: any) => setTargetAudience(e.target.value)}
          />
          {knowledgeBase && (
            <FormControl fullWidth style={{ marginTop: 20 }}>
              <InputLabel>Document Scope</InputLabel>
              <Select
                fullWidth
                value={documentScope}
                label="Document Scope"
                onChange={handleDocumentChange}
              >
                <MenuItem key={"none"} value={"none"}>
                  None
                </MenuItem>
                <MenuItem key={"root"} value={"root"}>
                  <IconMenuItem name={"/"} icon={<Source />} />
                </MenuItem>
                <MenuItem key={"shared"} value={"/shared"}>
                  <IconMenuItem
                    name={company.name + " Shared"}
                    icon={<Source color="primary" />}
                  />
                </MenuItem>
                {documents.map((doc) => (
                  <MenuItem key={doc.id} value={doc.id}>
                    <IconMenuItem
                      name={doc.name}
                      icon={
                        doc.type === "folder" ? <Source /> : <Description />
                      }
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {["article", "large_article"].includes(projectType) && (
            <Autocomplete
              multiple
              freeSolo
              filterSelectedOptions
              sx={{ mt: 2 }}
              options={keywords}
              getOptionLabel={(option: string) => option}
              onChange={(e, v) => setKeywords(v)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Keywords"
                  placeholder="Add SEO Keywords"
                  helperText="Click enter to add keyword to list"
                />
              )}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Chip label="5-15 Credits" style={{ marginRight: 5 }} />
          <LoadingButton
            onClick={createProjectParent}
            loading={projectDialogLoading}
          >
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <OutlineEditor
        eventListener={eventListener}
        outline={outline}
        setOutline={setOutline}
        open={outlineEditorOpen}
        onClose={() => setOutlineEditorOpen(false)}
        submitOutline={submitOutline}
      />
    </React.Fragment>
  );
};
