import { Firestore } from "@firebase/firestore";
import { IosShare, RestartAltOutlined } from "@mui/icons-material";
import Add from "@mui/icons-material/Add";
import AddPhotoAlternateOutlined from "@mui/icons-material/AddPhotoAlternateOutlined";
import Description from "@mui/icons-material/Description";
import DescriptionOutlined from "@mui/icons-material/DescriptionOutlined";
import SellIcon from "@mui/icons-material/Sell";
import SendIcon from "@mui/icons-material/Send";
import SourceIcon from "@mui/icons-material/Source";
import TuneIcon from "@mui/icons-material/Tune";
import UploadFileOutlined from "@mui/icons-material/UploadFileOutlined";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { User } from "firebase/auth";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import capture from "../../capture";
import ChatConversation from "../../components/ChatConversation";
import IconMenuItem from "../../components/IconMenuItem";
import LoadingScreen from "../../components/LoadingScreen";
import ModelSelector from "../../components/ModelSelector";
import SelectEmployeeDialog from "../../components/SelectEmployee";
import {
  modelsLabelsList,
  O1_STRATEGIES,
  STRATEGIES,
  visionModels,
} from "../../constants";
import { Company, Document, Employee, Message } from "../../types";
import { fileToBase64, hostname, parseError } from "../../utils";
import ChatApi from "../chat/chatApi";
import ChatLog from "../chat/ChatLog";
import ChatContext from "./ChatContext";
import ShareChatDialog from "./ShareChatDialog";

const listReducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_LIST":
      return { chat: action.list };
    case "ADD_ITEM":
      return state.chat.map.concat(action.item);
    case "UPDATE_ITEM":
      return state.chat.map((x: Message) => {
        if (x.id === action.message.id) {
          return action.message;
        }
        return x;
      });
    case "APPEND_TEXT":
      return state.chat.map((x: Message) => {
        if (x.id === action.id) {
          return {
            ...x,
            text: x.text + action.text,
            loading: action.loading,
          };
        }
        return x;
      });
    case "UPDATE_CONTEXT":
      return state.chat.map((x: Message) => {
        if (x.id === action.id) {
          return {
            ...x,
            context: action.context,
          };
        }
        return x;
      });
    default:
      throw new Error();
  }
};

export default ({
  db,
  currentUser,
  id,
  companyId,
  token,
  getToken,
  eventListener,
  mode,
  instanceConfig,
}: {
  db: Firestore;
  currentUser: any | User;
  id: string | any;
  companyId: string;
  token: any | string;
  getToken: Function;
  eventListener: Function;
  mode: string;
  instanceConfig: any;
}) => {
  const [prompt, setPrompt] = useState("");
  const [chat, setChat] = useState([]);
  const [employees, setEmployees] = useState<Array<Employee>>([]);
  const [employee, setEmployee] = useState<Employee>({
    uid: "",
    id: "",
    name: "",
    title: "",
    companyId: "",
    prompt: "",
    tags: [],
    picture: "",
  });
  const [company, setCompany] = useState<Company>({
    uid: "",
    id: "",
    name: "",
    type: "",
    description: "",
    website: "",
    employees: [],
  });
  const [currentPlan, setCurrentPlan] = useState("free");
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const [init, setInit] = useState(false);
  const [recording, setRecording] = useState(false);
  const [documents, setDocuments] = useState<Array<Document>>([]);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [selectEmployeesOpen, setSelectEmployeesOpen] = useState(false);
  const chatLogRef = useRef();
  const [contextSettings, setContextSettings] = useState({
    model: modelsLabelsList[0].name,
    memoryScope: "chat",
    searchEngine: false,
    shortTermMemories: true,
    longTermMemories: true,
    documents: true,
    company: true,
    employee: true,
    strategy: "one-shot",
    websites: false,
    websitesJs: false,
    documentScope: "root",
    documentPath: "/",
    documentStrategy: "similarity-ranking",
  });
  const [dialogSelectedMessage, setDialogSelectedMessage] =
    useState<Message | null>(null);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [folderPath, setFolderPath] = useState("/");
  const [image, setImage] = useState("");
  const [requestDocuments, setRequestDocuments] = useState<Array<any>>([]);
  const [chatLoading, setChatLoading] = useState(false);
  const [strategyOptions, setStrategyOptions] = useState(STRATEGIES);
  const [shareOpen, setShareOpen] = useState(false);
  const navigate = useNavigate();
  const chatApi = new ChatApi();
  const theme = useTheme();
  const displayValue = id ? "block" : "none";
  chatApi.init(eventListener, getToken);

  const onModelChange = (e: any) => {
    var update: any = {
      model: e.target.value,
    };
    if (e.target.value.includes("o1")) {
      setStrategyOptions(O1_STRATEGIES);
      update["strategy"] = "one-shot";
    } else {
      setStrategyOptions(STRATEGIES);
    }
    setContextSettings({
      ...contextSettings,
      ...update,
    });
  };

  const getMessageBorderRadius = (role: String) => {
    return role !== "user" ? "20px 20px 20px 1px" : "20px 20px 1px 20px";
  };

  const getEmployees = async (employeeId: string) => {
    const currentToken = await getToken();
    await axios
      .get(`${hostname}/companies/${companyId}/employees`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setEmployees(res.data.employees);
        if (employeeId) {
          setEmployee(res.data.employees.find((x: any) => x.id === employeeId));
        }
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const createChat = async (employee: Employee) => {
    capture("Created new chat", {});
    const currentToken = await getToken();
    const chatId = uuidv4();
    await axios
      .post(
        `${hostname}/chats`,
        {
          id: chatId,
          companyId: companyId,
          ts: new Date().getTime(),
          employeeId: employee.id,
          settings: contextSettings,
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
    if (chatLogRef && chatLogRef.current) {
      // @ts-ignore
      chatLogRef.current.refreshChats();
    }
    navigate("/dashboard/companies/" + companyId + "/chats/" + chatId);
  };

  const getCompany = async (companyId: string) => {
    const currentToken = await getToken();
    await axios
      .get(`${hostname}/companies/${companyId}`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setCompany(res.data.company);
        if (res.data.company.subscriptionType) {
          setCurrentPlan(res.data.company.subscriptionType);
        }
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const getDocuments = async (
    path: string,
    selectedDoc: Document | undefined = undefined
  ) => {
    const currentToken = await getToken();
    await axios
      .get(`${hostname}/documents`, {
        headers: { Authorization: `Bearer ${currentToken}` },
        params: { path: path, companyId: companyId },
      })
      .then((res) => {
        var documentList = res.data;
        if (selectedDoc) {
          setDocuments([selectedDoc, ...documentList]);
        } else {
          setDocuments(documentList);
        }
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const getData = async () => {
    if (!id) {
      return getEmployees("").then(() => {
        setChatLoading(false);
        setLoading(false);
      });
    }
    const currentToken = await getToken();
    const empFuture = axios
      .get(`${hostname}/chats/${companyId}/${id}`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        var docFuture;
        const currentChat = res.data.chat;
        if (currentChat.settings) {
          var newSettings = { ...contextSettings, ...currentChat.settings };
          onModelChange({ target: { value: newSettings.model } });
          setContextSettings(newSettings);
          docFuture = getDocuments(newSettings.documentPath);
        } else {
          docFuture = getDocuments(folderPath);
        }
        return Promise.all([
          getEmployees(currentChat.employeeId),
          getCompany(currentChat.companyId),
          docFuture,
        ]);
      });

    const chatFuture = chatApi
      .getMessages(companyId, id)
      .then((messages: any) => {
        setChat(messages);
      });
    await Promise.all([empFuture, chatFuture]);
    setLoading(false);
    setChatLoading(false);
  };

  useEffect(() => {
    if (currentUser.uid) {
      setChatLoading(true);
      setInit(true);
      getData();
    }
  }, [id, currentUser, companyId]);

  const refreshChat = async (
    userMessage: Message,
    assistantMessage: Message
  ) => {
    await chatApi
      .getMessageContext(companyId, id, assistantMessage.id)
      .then((msgContext) => {
        setChat((chat: any) =>
          chat.map((x: Message) => {
            if (x.id === assistantMessage.id) {
              return {
                ...x,
                context: msgContext,
              };
            }
            return x;
          })
        );
      });
    if (chatLogRef && chatLogRef.current) {
      // @ts-ignore
      chatLogRef.current.refreshChats();
    }
  };

  const sendMessage = async () => {
    if (prompt === "") return;
    chatApi.sendPyPrompt(
      prompt,
      chat,
      documents,
      setChat,
      employee,
      { ...contextSettings, image: image, requestDocuments: requestDocuments },
      setPrompt,
      refreshChat,
      company,
      currentUser,
      id
    );
    setImage("");
    setRequestDocuments([]);
  };

  const handleDocumentChange = (e: any) => {
    var newContextSettings = {
      ...contextSettings,
      documentScope: e.target.value,
    };
    setContextSettings(newContextSettings);
    const selectedDoc = documents.find((x) => x.id === e.target.value);
    if (e.target.value === "root") {
      setFolderPath("/");
      getDocuments("/");
    }
    if (e.target.value === "/shared") {
      setFolderPath("/shared");
      getDocuments("/shared");
    }
    if (!selectedDoc) {
      return;
    }
    if (selectedDoc.type === "folder") {
      const newPath =
        selectedDoc.path !== "/"
          ? `${selectedDoc.path}/${selectedDoc.name}`
          : `/${selectedDoc.name}`;
      setFolderPath(newPath);
      getDocuments(newPath, selectedDoc);
      setContextSettings({ ...newContextSettings, documentPath: newPath });
    }
  };

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const base64 = await fileToBase64(file);
      setImage(base64);
    }
  };

  const handleDocumentFileChange = async (event: any) => {
    const files = event.target.files;
    const formData = new FormData();
    for (var i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    const currentToken = await getToken();
    await axios
      .post(`${hostname}/documents/convert`, formData, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res: any) => {
        setRequestDocuments([...requestDocuments, ...res.data.documents]);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  if (loading) {
    return <LoadingScreen open={loading} />;
  }

  const settingsClose = () => {
    setSettingsOpen(false);
  };

  const saveSettings = () => {
    chatApi.saveChatSettings(id, contextSettings);
    setSettingsOpen(false);
  };

  const saveCompanyDefaultSettings = () => {
    chatApi.saveCompanyDefaultChatSettings(companyId, contextSettings);
    saveSettings();
  };

  const inputHeight = requestDocuments.length > 0 || image ? 445 : 396;

  return (
    <Box>
      <ShareChatDialog
        open={shareOpen}
        onClose={() => setShareOpen(false)}
        companyId={companyId}
        chatId={id}
        getToken={getToken}
      />
      <SelectEmployeeDialog
        open={selectEmployeesOpen}
        employees={employees}
        title="Select Employee"
        content="Choose an employee to chat with."
        buttonText="Submit"
        onClose={() => setSelectEmployeesOpen(false)}
        onSubmit={(employee: any) => {
          setSelectEmployeesOpen(false);
          createChat(employee);
        }}
      />
      <ChatContext
        message={dialogSelectedMessage}
        open={dialogSelectedMessage !== null}
        onClose={() => setDialogSelectedMessage(null)}
      />
      <Grid container spacing={0}>
        <Grid
          item
          display={{
            xs: id ? "none" : "block",
            md: "block",
            lg: "block",
          }}
          xs={id ? 2 : 12}
          sx={{
            backgroundColor: instanceConfig.menuColor,
            borderRight:
              theme.palette.mode === "dark"
                ? "1px solid rgba(255, 255, 255, 0.12)"
                : "1px solid rgba(0, 0, 0, 0.12)",
          }}
        >
          <Button
            fullWidth
            sx={{ mt: 1, mb: 2 }}
            startIcon={<Add />}
            onClick={() => setSelectEmployeesOpen(true)}
          >
            New Chat
          </Button>
          <ChatLog
            ref={chatLogRef}
            style={{
              maxHeight: "calc(100vh - 125px)",
              height: "calc(100vh - 125px)",
              width: "100%",
              overflow: "scroll",
            }}
            companyId={companyId}
            getToken={getToken}
            currentUser={currentUser}
            currentChatId={id}
            perPage={25}
            eventListener={eventListener}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={10}
          display={{ xs: displayValue, md: displayValue }}
        >
          {chatLoading && <LoadingScreen open={true} />}
          <Box
            sx={{
              maxHeight: "calc(100vh - 70px)",
              overflow: "scroll",
              display: chatLoading ? "none" : "block",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: 15,
                borderBottom:
                  theme.palette.mode === "dark"
                    ? "1px solid rgba(255, 255, 255, 0.12)"
                    : "1px solid rgba(0, 0, 0, 0.12)",
              }}
            >
              <Avatar
                src={employee.picture}
                style={{ height: 60, width: 60, marginRight: 10 }}
              />
              <Typography gutterBottom variant="h5" color="textSecondary">
                {`${employee.name} (${employee.title})`}
              </Typography>
              <Box sx={{ flex: 1 }}></Box>
              <Tooltip title={"Share chat"}>
                <IconButton onClick={() => setShareOpen(true)}>
                  <IosShare />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Restart chat"}>
                <IconButton onClick={() => createChat(employee)}>
                  <RestartAltOutlined />
                </IconButton>
              </Tooltip>
            </div>
            <Box
              sx={{
                height: `calc(100vh - ${inputHeight}px)`,
                overflow: "scroll",
                pl: 2,
                pr: 2,
                overflowY: "auto",
                overscrollBehavior: "contain",
                webkitOverflowScrolling: "touch",
              }}
            >
              <List>
                {!chat.length && (
                  <ListItem
                    style={{
                      padding: "0px, auto",
                    }}
                  >
                    <Card
                      sx={{ borderRadius: getMessageBorderRadius("agent") }}
                    >
                      <CardHeader
                        style={{
                          float: "left",
                          width: "100%",
                        }}
                        avatar={
                          <Avatar
                            alt={employee.name}
                            sx={{ width: 30, height: 30 }}
                            src={employee.picture}
                          />
                        }
                        title={employee.name}
                      />
                      <CardContent>Hi! What can I help you with?</CardContent>
                    </Card>
                  </ListItem>
                )}
                <ChatConversation
                  chat={chat}
                  mode={mode}
                  eventListener={eventListener}
                  setDialogSelectedMessage={setDialogSelectedMessage}
                />
              </List>
            </Box>
            <div
              style={{
                padding: 10,
                borderTop:
                  theme.palette.mode === "dark"
                    ? "1px solid rgba(255, 255, 255, 0.12)"
                    : "1px solid rgba(0, 0, 0, 0.12)",
              }}
            >
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  sendMessage();
                }}
              >
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      multiline
                      minRows={4}
                      maxRows={4}
                      fullWidth
                      placeholder="Type question here (Shift+Enter=New Line, Enter=Send Message)"
                      value={prompt}
                      onChange={(e) => setPrompt(e.target.value)}
                      variant="outlined"
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault();
                          sendMessage();
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {visionModels.has(contextSettings.model) && (
                              <React.Fragment>
                                <input
                                  accept="image/*"
                                  style={{ display: "none" }}
                                  id="icon-button-image"
                                  type="file"
                                  onChange={handleFileChange}
                                  onClick={(event: any) => {
                                    event.target.value = null;
                                  }}
                                />
                                <label htmlFor="icon-button-image">
                                  <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                  >
                                    <AddPhotoAlternateOutlined />
                                  </IconButton>
                                </label>
                              </React.Fragment>
                            )}
                            <React.Fragment>
                              <input
                                accept=".doc,.docx,.pdf,.csv,.xlsx,.xls"
                                style={{ display: "none" }}
                                id="icon-button-file"
                                type="file"
                                multiple
                                onChange={handleDocumentFileChange}
                                onClick={(event: any) => {
                                  event.target.value = null;
                                }}
                              />
                              <label htmlFor="icon-button-file">
                                <IconButton
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <UploadFileOutlined />
                                </IconButton>
                              </label>
                            </React.Fragment>
                            <IconButton color="primary" type="submit">
                              <SendIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  {image && (
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2}>
                        <Chip
                          avatar={<Avatar src={image} />}
                          label={"Image"}
                          variant="outlined"
                          onDelete={() => setImage("")}
                        />
                      </Stack>
                    </Grid>
                  )}
                  {requestDocuments.length > 0 && (
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2}>
                        {requestDocuments.map((document) => (
                          <Chip
                            key={document.id}
                            icon={<DescriptionOutlined />}
                            label={document.name}
                            variant="outlined"
                            onDelete={() =>
                              setRequestDocuments(
                                requestDocuments.filter(
                                  (x) => x.id !== document.id
                                )
                              )
                            }
                          />
                        ))}
                      </Stack>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button
                      style={{ height: 40, marginBottom: 10 }}
                      fullWidth
                      variant="outlined"
                      type="button"
                      onClick={() => setSettingsOpen(true)}
                    >
                      Settings
                      <TuneIcon style={{ marginLeft: 8 }} fontSize="small" />
                    </Button>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <Typography variant="caption">
                        Parallel AI can make mistakes. Consider checking
                        important information.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid>
                    <Dialog
                      open={settingsOpen}
                      onClose={(e) => {
                        setSettingsOpen(false);
                      }}
                    >
                      <DialogTitle>Settings</DialogTitle>
                      <DialogContent>
                        <DialogContentText>External Sources</DialogContentText>
                        <FormControlLabel
                          control={
                            <Switch
                              onChange={(e) => {
                                setContextSettings({
                                  ...contextSettings,
                                  // @ts-ignore
                                  documents: e.target.checked,
                                });
                              }}
                              checked={contextSettings.documents}
                            />
                          }
                          label="Knowledge Base"
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              onChange={(e) => {
                                setContextSettings({
                                  ...contextSettings,
                                  // @ts-ignore
                                  searchEngine: e.target.checked,
                                });
                              }}
                              checked={contextSettings.searchEngine}
                            />
                          }
                          label="Search Engine Results"
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              onChange={(e) => {
                                setContextSettings({
                                  ...contextSettings,
                                  // @ts-ignore
                                  websitesJs: e.target.checked,
                                });
                              }}
                              checked={contextSettings.websitesJs}
                            />
                          }
                          label="Websites"
                        />
                        <DialogContentText style={{ marginTop: 10 }}>
                          Memory Recall
                        </DialogContentText>
                        <FormControlLabel
                          control={
                            <Switch
                              onChange={(e: any) => {
                                setContextSettings({
                                  ...contextSettings,
                                  longTermMemories: e.target.checked,
                                });
                              }}
                              checked={contextSettings.longTermMemories}
                            />
                          }
                          label="Long-Term Memories"
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              onChange={(e: any) => {
                                setContextSettings({
                                  ...contextSettings,
                                  shortTermMemories: e.target.checked,
                                });
                              }}
                              checked={contextSettings.shortTermMemories}
                            />
                          }
                          label="Short-Term Memories"
                        />
                        <DialogContentText style={{ marginTop: 10 }}>
                          System Persona
                        </DialogContentText>
                        <FormControlLabel
                          control={
                            <Switch
                              onChange={(e) => {
                                setContextSettings({
                                  ...contextSettings,
                                  // @ts-ignore
                                  employee: e.target.checked,
                                });
                              }}
                              checked={contextSettings.employee}
                            />
                          }
                          label="Employee Context"
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              onChange={(e) => {
                                setContextSettings({
                                  ...contextSettings,
                                  // @ts-ignore
                                  company: e.target.checked,
                                });
                              }}
                              checked={contextSettings.company}
                            />
                          }
                          label="Company Context"
                        />
                        <ModelSelector
                          tools={false}
                          currentPlan={currentPlan}
                          value={contextSettings.model}
                          onChange={onModelChange}
                        />
                        {contextSettings.longTermMemories && (
                          <FormControl fullWidth style={{ marginTop: 20 }}>
                            <InputLabel>Long-Term Memory Scope</InputLabel>
                            <Select
                              label="Long-Term Memory Scope"
                              value={contextSettings.memoryScope}
                              onChange={(e: any) =>
                                setContextSettings({
                                  ...contextSettings,
                                  memoryScope: e.target.value,
                                })
                              }
                            >
                              <MenuItem value="chat">Current Chat</MenuItem>
                              <MenuItem value="all">All Chats</MenuItem>
                            </Select>
                          </FormControl>
                        )}
                        <FormControl fullWidth style={{ marginTop: 20 }}>
                          <InputLabel>Strategy</InputLabel>
                          <Select
                            label="Strategy"
                            value={contextSettings.strategy}
                            onChange={(e: any) =>
                              setContextSettings({
                                ...contextSettings,
                                strategy: e.target.value,
                              })
                            }
                          >
                            {strategyOptions.map((option: any) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {contextSettings.documents && (
                          <FormControl fullWidth style={{ marginTop: 20 }}>
                            <InputLabel>Document Scope</InputLabel>
                            <Select
                              value={contextSettings.documentScope}
                              label="Knowledge Base Scope"
                              onChange={handleDocumentChange}
                            >
                              <MenuItem key={"tags"} value={"tags"}>
                                <IconMenuItem
                                  name={"Document Tags"}
                                  icon={<SellIcon />}
                                />
                              </MenuItem>
                              <MenuItem key={"root"} value={"root"}>
                                <IconMenuItem
                                  name={"/"}
                                  icon={<SourceIcon />}
                                />
                              </MenuItem>
                              <MenuItem key={"shared"} value={"/shared"}>
                                <IconMenuItem
                                  name={company.name + " Shared"}
                                  icon={<SourceIcon color="primary" />}
                                />
                              </MenuItem>
                              {documents.map((doc) => (
                                <MenuItem key={doc.id} value={doc.id}>
                                  <IconMenuItem
                                    name={doc.name}
                                    icon={
                                      doc.type === "folder" ? (
                                        <SourceIcon />
                                      ) : (
                                        <Description />
                                      )
                                    }
                                  />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                        {contextSettings.documents && (
                          <FormControl fullWidth style={{ marginTop: 20 }}>
                            <InputLabel>Document Strategy</InputLabel>
                            <Select
                              value={contextSettings.documentStrategy}
                              label="Document Strategy"
                              onChange={(e) =>
                                setContextSettings({
                                  ...contextSettings,
                                  documentStrategy: e.target.value,
                                })
                              }
                            >
                              <MenuItem value="similarity-ranking">
                                Similarity Ranking
                              </MenuItem>
                              <MenuItem value="full-documents">
                                Full Documents
                                <Chip
                                  label="+5 Credits"
                                  style={{ marginLeft: 5 }}
                                />
                              </MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={settingsClose}>Cancel</Button>
                        <Button onClick={saveCompanyDefaultSettings}>
                          Save As Default
                        </Button>
                        <Button onClick={saveSettings} variant="outlined">
                          Save
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
