import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { hostname } from "../../utils";

export default ({
  open,
  onClose,
  list,
  getToken,
  eventListener,
}: {
  open: boolean;
  onClose: () => void;
  list: any;
  getToken: Function;
  eventListener: Function;
}) => {
  const [sharing, setSharing] = useState("Private");

  const saveSharing = async () => {
    const currentToken = await getToken();
    axios
      .put(
        `${hostname}/lists`,
        { ...list, sharing },
        {
          headers: { Authorization: `Bearer ${currentToken}` },
        }
      )
      .then(() => {
        onClose();
      });
  };

  useEffect(() => {
    if (list) {
      setSharing(list.sharing);
    }
  }, [list]);

  if (!list) {
    return <></>;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Sharing {list.name}</DialogTitle>
      <DialogContent>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={sharing === "Shared"}
                onChange={() =>
                  setSharing(sharing === "Shared" ? "Private" : "Shared")
                }
              />
            }
            label="Shared"
          />
        </FormGroup>
        <Button sx={{ mt: 3 }} variant={"outlined"} onClick={saveSharing}>
          Save
        </Button>
      </DialogContent>
    </Dialog>
  );
};
