import { CircularProgress, IconButton } from "@mui/material";
import React from "react";

const LoadingIconButton = ({
  children,
  loading = false,
  onClick,
  ...props
}: any) => {
  const getIcon = () => {
    if (loading) {
      return <CircularProgress size={20} color="inherit" />;
    } else {
      return children;
    }
  };

  return (
    <IconButton onClick={onClick} disabled={loading} {...props}>
      {getIcon()}
    </IconButton>
  );
};

export default LoadingIconButton;
