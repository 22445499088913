import { Source } from "@mui/icons-material";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Company, Document } from "../types";
import { hostname, parseError } from "../utils";
import IconMenuItem from "./IconMenuItem";

export default ({
  getToken,
  eventListener,
  company,
  onValueChange,
  value = "/",
}: {
  getToken: Function;
  eventListener: Function;
  company: Company;
  value: string;
  onValueChange: (folderId: string) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [folders, setFolders] = useState<any>([]);

  const getFolders = async () => {
    const currentToken = await getToken();
    axios
      .get(`${hostname}/documents/folders`, {
        params: {
          companyId: company.id,
        },
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setFolders(res.data.folders);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDocumentChange = (e: any) => {
    const folder = folders.find((x: any) => getValuePath(x) === e.target.value);
    if (folder && folder.folders) {
      setFolders([folder, ...folder.folders]);
    }
    if (e.target.value === "root") {
      getFolders();
    }
    onValueChange(e.target.value);
  };

  const getValuePath = (doc: any) => {
    if (doc.path === "/") {
      return doc.path + doc.name;
    }
    return doc.path + "/" + doc.name;
  };

  useEffect(() => {
    getFolders();
  }, []);

  return (
    <FormControl fullWidth style={{ marginTop: 20 }}>
      <InputLabel>Destination Path</InputLabel>
      <Select
        value={value}
        label="Destination Path"
        onChange={handleDocumentChange}
      >
        <MenuItem key={"root"} value={"/"}>
          <IconMenuItem name={"/"} icon={<Source />} />
        </MenuItem>

        {folders.map((doc: Document) => (
          <MenuItem key={doc.id} value={getValuePath(doc)}>
            {(doc.name === "shared" && doc.path === "/" && (
              <IconMenuItem
                name={company.name + " Shared"}
                icon={<Source color="primary" />}
              />
            )) || <IconMenuItem name={doc.name} icon={<Source />} />}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
