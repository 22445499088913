import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { CopyBlock, dracula } from "react-code-blocks";
import LoadingButton from "../../components/LoadingButton";
import { hostname } from "../../utils";

export default ({
  open,
  onClose,
  companyId,
  chatId,
  getToken,
}: {
  open: boolean;
  onClose: () => void;
  companyId: string;
  chatId: string;
  getToken: Function;
}) => {
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState(false);

  const createShareableLink = async () => {
    setLoading(true);
    const currentToken = await getToken();
    await axios
      .post(
        `${hostname}/chats/${companyId}/${chatId}/share`,
        {},
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .then((res) => {
        setLink(`https://parallellabs.app/conversation/${res.data.id}`);
      });
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Share Chat</DialogTitle>
      <DialogContent>
        <LoadingButton
          loading={loading}
          onClick={createShareableLink}
          variant={"outlined"}
        >
          Create Shareable Link
        </LoadingButton>
        {link && (
          <div
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <CopyBlock
              language={"javascript"}
              text={link}
              wrapLines
              codeBlock
              showLineNumbers={false}
              theme={dracula}
            />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
