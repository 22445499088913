import axios from "axios";
import { User } from "firebase/auth";
import { Company, Document, Employee, Message } from "../../types";
import { hostname, parseError, uniqueId } from "../../utils";

export default class {
  eventListener: Function = () => {};
  getToken: Function = () => {};
  init(listener: Function, getToken: Function) {
    this.eventListener = listener;
    this.getToken = getToken;
  }

  async saveCompanyDefaultChatSettings(companyId: string, settings: any) {
    const token = await this.getToken();
    return axios
      .post(`${hostname}/companies/${companyId}/settings`, settings, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((err) => {
        this.eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  }

  async saveChatSettings(chatId: string, settings: any) {
    const token = await this.getToken();
    return axios
      .post(`${hostname}/chats/${chatId}/settings`, settings, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((err) => {
        this.eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  }

  async syncCompletion(prompt: string) {
    try {
      const token = await this.getToken();
      const response = await fetch(`${hostname}/completions/messages`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          messages: [{ role: "user", content: prompt }],
        }),
      });
      const jsonResponse = await response.json();
      return jsonResponse.choices[0].message.content;
    } catch (err) {
      this.eventListener({
        type: "SET_ERROR",
        error: parseError(err),
      });
    }
  }

  async createEmployees(
    company: Company,
    numEmployees: number,
    batchSize: number,
    jobTitle: null | string,
    setMessage: Function
  ) {
    try {
      for (let i = 1; i <= numEmployees; i += batchSize) {
        setMessage(`Generating employee ${i} of ${numEmployees}`);
        await this.createPyEmployee(company, batchSize, jobTitle);
      }
    } catch (err) {
      this.eventListener({
        type: "SET_ERROR",
        error: parseError(err),
      });
    }
  }

  async createPyEmployee(
    company: Company,
    numEmployees: number,
    jobTitle: null | string
  ) {
    try {
      const token = await this.getToken();
      await fetch(`${hostname}/employees`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          companyId: company.id,
          title: jobTitle,
          numEmployees: numEmployees,
        }),
      });
    } catch (err) {
      this.eventListener({
        type: "SET_ERROR",
        error: parseError(err),
      });
    }
  }

  async sendPyPrompt(
    prompt: string,
    chat: Array<Message>,
    documents: Array<Document>,
    setChat: Function,
    employee: Employee,
    contextSettings: any,
    setPrompt: Function,
    refreshChat: Function,
    company: Company,
    currentUser: User,
    chatId: string
  ) {
    const token = await this.getToken();
    const userMessage = {
      id: uniqueId(),
      user: currentUser.displayName || currentUser.email || "Human",
      picture: currentUser.photoURL || "",
      role: "user",
      text: `${prompt}`,
      ts: new Date().getTime(),
      context: {
        memories: [],
      },
    };
    var assistantMessage = {
      id: uniqueId(new Date().getTime() + 1),
      user: `${employee.name} (${employee.title})`,
      role: "assistant",
      picture: employee.picture,
      text: "",
      ts: new Date().getTime() + 1,
      context: {
        memories: [],
      },
    };
    setChat((chat: any) => {
      return [...chat, userMessage, { ...assistantMessage, loading: true }];
    });
    setPrompt("");
    const response = await fetch(`${hostname}/completions/chat`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        prompt: prompt,
        stream: true,
        chatId: chatId,
        employee: employee,
        company: company,
        userMessage: userMessage,
        assistantMessage: assistantMessage,
        contextSettings: contextSettings,
      }),
    });
    if (!response.body) return;
    const reader = response.body
      .pipeThrough(new TextDecoderStream())
      .getReader();
    var contextJson = "";
    var contextNotDone = false;
    while (true) {
      var { value, done } = await reader.read();
      if (done) break;
      if (
        (value && contextNotDone) ||
        (value && value.length > 3 && value.slice(0, 3) === "^C^")
      ) {
        try {
          contextNotDone = value.substring(value.length - 3) !== "^C^";
          contextJson += value;
          if (contextNotDone) {
            continue;
          }
          const splits = contextJson.split("^C^");
          const contextStr = splits[1];
          userMessage.context = JSON.parse(contextStr);

          value = splits[2];
          contextNotDone = false;
          contextJson = "";
        } catch (e) {
          console.error("Error parsing json", value);
        }
      }
      if (value && value.length > 3 && value.slice(0, 3) === "^E^") {
        const splits = value.split("^E^");
        const errStr = splits[1];
        this.eventListener({
          type: "SET_ERROR",
          error: errStr,
        });
        value = splits[2];
      }
      if (value) {
        setChat((chat: any) => {
          return chat.map((x: any) => {
            if (x.id === assistantMessage.id) {
              return {
                ...x,
                text: x.text + value,
                loading: true,
              };
            }
            return x;
          });
        });
      }
    }
    setChat((chat: any) => {
      return chat.map((x: any) => {
        if (x.id === assistantMessage.id) {
          return {
            ...x,
            loading: false,
          };
        }
        return x;
      });
    });
    refreshChat(userMessage, assistantMessage);
  }

  async getMessages(companyId: string, chatId: string): Promise<any> {
    const token = await this.getToken();
    return axios
      .get(`${hostname}/messages/${companyId}/${chatId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        this.eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
        return [];
      });
  }

  async getMessageContext(
    companyId: string,
    chatId: string,
    messageId: string
  ): Promise<any> {
    const token = await this.getToken();
    return axios
      .get(`${hostname}/messages/${companyId}/${chatId}/${messageId}/context`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        this.eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  }
}
